body {
    font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    color: #4E5242;
    margin: 0 auto;
    padding: 0;
    background: #D4CDAA url('../images/Page-BgSimpleGradient.jpg') repeat-x !important;
}

.Main {
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
}

hr {
    background-color: #795539;
    height: 4px;
}

.cleared {
    float: none;
    clear: both;
    margin: 0;
    padding: 0;
    border: none;
    font-size: 1px;
}

form {
    padding: 0 !important;
    margin: 0 !important;
}

table.position {
    position: relative;
    width: 100%;
    table-layout: fixed;
}
/* end Page */

/* begin Box, Sheet */
.Sheet {
    position: relative;
    z-index: 0;
    margin: 0 auto;
    max-width: 900px;
    min-width: 51px;
    min-height: 51px;
}

.Sheet-body {
    position: relative;
    z-index: 1;
    padding: 5px;
    overflow: hidden;
}

.Sheet-tr, .Sheet-tl, .Sheet-br, .Sheet-bl, .Sheet-tc, .Sheet-bc, .Sheet-cr, .Sheet-cl {
    position: absolute;
    z-index: -1;
}

.Sheet-tr, .Sheet-tl, .Sheet-br, .Sheet-bl {
    width: 70px;
    height: 70px;
    background-image: url('../images/Sheet-s.png');
}

.Sheet-tl {
    top: 0;
    left: 0;
    clip: rect(auto, 35px, 35px, auto);
}

.Sheet-tr {
    top: 0;
    right: 0;
    clip: rect(auto, auto, 35px, 35px);
}

.Sheet-bl {
    bottom: 0;
    left: 0;
    clip: rect(35px, 35px, auto, auto);
}

.Sheet-br {
    bottom: 0;
    right: 0;
    clip: rect(35px, auto, auto, 35px);
}

.Sheet-tc, .Sheet-bc {
    left: 35px;
    right: 35px;
    height: 70px;
    background-image: url('../images/Sheet-h.png');
}

.Sheet-tc {
    top: 0;
    clip: rect(auto, auto, 35px, auto);
}

.Sheet-bc {
    bottom: 0;
    clip: rect(35px, auto, auto, auto);
}

.Sheet-cr, .Sheet-cl {
    top: 35px;
    bottom: 35px;
    width: 70px;
    background-image: url('../images/Sheet-v.png');
}

.Sheet-cr {
    right: 0;
    clip: rect(auto, auto, auto, 35px);
}

.Sheet-cl {
    left: 0;
    clip: rect(auto, 35px, auto, auto);
}

.Sheet-cc {
    position: absolute;
    z-index: -1;
    top: 35px;
    left: 35px;
    right: 35px;
    bottom: 35px;
    background-color: #F0EDE0;
}

.Sheet {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
}

/* end Box, Sheet */

/* begin Header */
div.Header {
    margin: 0 auto;
    position: relative;
    z-index: 0;
    max-width: 890px;
    height: 200px;
}

div.Header-png {
    z-index: -2;
    top: 0;
    left: 0;
    max-width: 1170px;
    height: 240px;
    background: url('../images/Header.png') no-repeat left top;
    background-size: 100% 100%;
    border-radius: 15px 15px 0 0;
}

/* end Header */

/* begin Logo */
.logo {
    display: block;
    position: absolute;
    top: 210px;
    width: 100%;
}

.logo-text {
    display: block;
    text-align: center;
}

.logo-text, .logo-text a {
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    color: #574C14 !important;
}

/* end Logo */

/* begin Menu */
/* menu structure */

.nav-list a, .nav-list a:link, .nav-list a:visited, .nav-list a:hover {
    text-align: left;
    text-decoration: none;
    outline: none;
    letter-spacing: normal;
    word-spacing: normal;
}

.nav-list, .nav-list ul {
    margin: 0;
    padding: 0;
    border: 0;
    list-style-type: none;
    display: block;
}

.nav-list li {
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
    float: left;
    position: relative;
    z-index: 5;
    background: none;
}

.nav-list li:hover {
    z-index: 10000;
    white-space: normal;
}

.nav-list li li {
    float: none;
}

.nav-list ul {
    visibility: hidden;
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    background: none;
}

.nav-list li:hover > ul {
    visibility: visible;
    top: 100%;
}

.nav-list li li:hover > ul {
    top: 0;
    left: 100%;
}

.nav-list:after, .nav-list ul:after {
    content: ".";
    height: 0;
    display: block;
    visibility: hidden;
    overflow: hidden;
    clear: both;
}

.nav-list, .nav-list ul {
    min-height: 0;
}

.nav-list ul {
    background-image: url(../images/spacer.gif);
    padding: 10px 30px 30px 30px;
    margin: -10px 0 0 -30px;
}

.nav-list ul ul {
    padding: 30px 30px 30px 10px;
    margin: -30px 0 0 -10px;
}

/* menu structure */

.nav-list {
    padding: 4px 2px 0 2px;
}

.nav {
    position: relative;
    height: 30px;
    z-index: -1;
}

.nav .l, .nav .r {
    position: absolute;
    z-index: -1;
    top: 0;
    height: 30px;
    background-image: url('../images/nav.png');
}

.nav .l {
    left: 0;
    right: 0;
}

.nav .r {
    right: 0;
    max-width: 890px;
    clip: rect(auto, auto, auto, 890px);
}

/* end Menu */

/* begin MenuItem */
.nav-list ul li {
    clear: both;
}

.nav-list a {
    position: relative;
    display: block;
    overflow: hidden;
    height: 26px;
    cursor: pointer;
    text-decoration: none;
    margin-right: 4px;
    margin-left: 4px;
}

.nav-list a .r, .nav-list a .l {
    position: absolute;
    display: block;
    height: 78px;
    background-image: url('../images/MenuItem.png');
}

.nav-list a .l {
    left: 0;
    right: 11px;
}

.nav-list a .r {
    width: 422px;
    right: 0;
    clip: rect(auto, auto, auto, 411px);
}

.nav-list a .t {
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    color: #CFD2C6;
    padding: 0 2px;
    margin: 0 11px;
    line-height: 26px;
    text-align: center;
}

.nav-list a:hover .l, .nav-list a:hover .r {
    top: -26px;
}

.nav-list li:hover > a .l, .nav-list li:hover > a .r {
    top: -26px;
}

.nav-list li:hover a .l, .nav-list li:hover a .r {
    top: -26px;
}

.nav-list a:hover .t {
    color: #36392D;
}

.nav-list li:hover a .t {
    color: #36392D;
}

.nav-list li:hover > a .t {
    color: #36392D;
}

.nav-list a.active .l, .nav-list a.active .r {
    top: -52px;
}

.nav-list a.active .t {
    color: #131410;
}

/* end MenuItem */

/* begin MenuSubItem */
.nav-list ul a {
    display: block;
    text-align: center;
    white-space: nowrap;
    height: 20px;
    width: 180px;
    overflow: hidden;
    line-height: 20px;
    margin-right: auto;

    background: url('../images/subitem-bg.png') repeat-x left top;
    border: 0 solid;
}

.nav ul.nav-list ul span, .nav ul.nav-list ul span span {
    display: inline;
    float: none;
    margin: inherit;
    padding: inherit;
    background-image: none;
    text-align: inherit;
    text-decoration: inherit;
}

.nav-list ul a, .nav-list ul a:link, .nav-list ul a:visited, .nav-list ul a:hover, .nav-list ul a:active, .nav ul.nav-list ul span, .nav ul.nav-list ul span span {
    text-align: left;
    text-indent: 12px;
    text-decoration: none;
    line-height: 20px;
    color: #554E2B;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
}

.nav-list ul ul a {
    margin-left: auto;
}

.nav-list ul li a:hover {
    color: #443E22;
    background-position: 0 -20px;
}

.nav-list ul li:hover > a {
    color: #443E22;
    background-position: 0 -20px;
}

.nav .nav-list ul li a:hover span, .nav .nav-list ul li a:hover span span {
    color: #443E22;
}

.nav .nav-list ul li:hover > a span, .nav .nav-list ul li:hover > a span span {
    color: #443E22;
}

/* end MenuSubItem */

/* begin ContentLayout */
.contentLayout {
    position: relative;
    margin-bottom: 5px;
    max-width: 890px;
}

/* end ContentLayout */

/* begin Box, Block */
.Block {
    position: relative;
    z-index: 0;
    margin: 0 auto;
    min-width: 19px;
    min-height: 19px;
}

.Block-body {
    position: relative;
    z-index: 1;
    padding: 1px;
}

.Block-tr, .Block-tl, .Block-br, .Block-bl, .Block-tc, .Block-bc, .Block-cr, .Block-cl {
    position: absolute;
    z-index: -1;
}

.Block-tr, .Block-tl, .Block-br, .Block-bl {
    width: 18px;
    height: 18px;
    background-image: url('../images/Block-s.png');
}

.Block-tl {
    top: 0;
    left: 0;
    clip: rect(auto, 9px, 9px, auto);
}

.Block-tr {
    top: 0;
    right: 0;
    clip: rect(auto, auto, 9px, 9px);
}

.Block-bl {
    bottom: 0;
    left: 0;
    clip: rect(9px, 9px, auto, auto);
}

.Block-br {
    bottom: 0;
    right: 0;
    clip: rect(9px, auto, auto, 9px);
}

.Block-tc, .Block-bc {
    left: 9px;
    right: 9px;
    height: 18px;
    background-image: url('../images/Block-h.png');
}

.Block-tc {
    top: 0;
    clip: rect(auto, auto, 9px, auto);
}

.Block-bc {
    bottom: 0;
    clip: rect(9px, auto, auto, auto);
}

.Block-cr, .Block-cl {
    top: 9px;
    bottom: 9px;
    width: 18px;
    background-image: url('../images/Block-v.png');
}

.Block-cr {
    right: 0;
    clip: rect(auto, auto, auto, 9px);
}

.Block-cl {
    left: 0;
    clip: rect(auto, 9px, auto, auto);
}

.Block-cc {
    position: absolute;
    z-index: -1;
    top: 9px;
    left: 9px;
    right: 9px;
    bottom: 9px;
    background-color: #F3F1E7;
}

.Block {
    margin: 7px;
}

/* end Box, Block */

/* begin BlockHeader */
.BlockHeader {
    position: relative;
    z-index: 0;
    height: 30px;
    padding: 0 8px;
    margin-bottom: 0;
}

.BlockHeader .t {
    height: 30px;
    color: #000000;
    font-size: 15px;
    font-variant: small-caps;
    white-space: nowrap;
    padding: 0 8px;
    line-height: 30px;
}

.header-tag-icon {
    display: inline-block;
    padding: 0 0 0 23px;
    background: url('../images/BlockHeaderIcon.png') no-repeat left top;
    min-height: 12px;
    margin: 0 0 0 5px;
}

/* end BlockHeader */

/* begin Box, BlockContent */
.BlockContent {
    position: relative;
    z-index: 0;
    margin: 0 auto;
    min-width: 1px;
    min-height: 1px;
}

.BlockContent-body {
    position: relative;
    z-index: 1;
    padding: 8px;
}

.BlockContent-body {
    color: #646954;
    font-size: 13px;
}

.BlockContent-body a:link {
    color: #84731F;
    text-decoration: none;
}

.BlockContent-body a:visited, .BlockContent-body a.visited {
    color: #8E947A;
    text-decoration: none;
}

.BlockContent-body a:hover, .BlockContent-body a.hover {
    color: #AE9729;
    text-decoration: underline;
}

.BlockContent-body ul {
    list-style-type: none;
    color: #262820;
    margin: 0;
    padding: 0;
}

.BlockContent-body li {
    font-size: 13px;
}

.BlockContent-body ul li {
    padding: 0 0 0 26px;
    background: url('../images/BlockContentBullets.png') no-repeat;
    margin: 0.5em 0 0.5em 0;
    line-height: 1.2em;
}

/* end Box, BlockContent */

/* begin Box, Post */
.Post {
    position: relative;
    z-index: 0;
    margin: 0 auto;
    min-width: 23px;
    min-height: 23px;
}

.Post-body {
    position: relative;
    z-index: 1;
    padding: 18px;
}

.Post-tr, .Post-tl, .Post-br, .Post-bl, .Post-tc, .Post-bc, .Post-cr, .Post-cl {
    position: absolute;
    z-index: -1;
}

.Post-tr, .Post-tl, .Post-br, .Post-bl {
    width: 30px;
    height: 30px;
    background-image: url('../images/Post-s.png');
}

.Post-tl {
    top: 0;
    left: 0;
    clip: rect(auto, 15px, 15px, auto);
}

.Post-tr {
    top: 0;
    right: 0;
    clip: rect(auto, auto, 15px, 15px);
}

.Post-bl {
    bottom: 0;
    left: 0;
    clip: rect(15px, 15px, auto, auto);
}

.Post-br {
    bottom: 0;
    right: 0;
    clip: rect(15px, auto, auto, 15px);
}

.Post-tc, .Post-bc {
    left: 15px;
    right: 15px;
    height: 30px;
    background-image: url('../images/Post-h.png');
}

.Post-tc {
    top: 0;
    clip: rect(auto, auto, 15px, auto);
}

.Post-bc {
    bottom: 0;
    clip: rect(15px, auto, auto, auto);
}

.Post-cr, .Post-cl {
    top: 15px;
    bottom: 15px;
    width: 30px;
    background-image: url('../images/Post-v.png');
}

.Post-cr {
    right: 0;
    clip: rect(auto, auto, auto, 15px);
}

.Post-cl {
    left: 0;
    clip: rect(auto, 15px, auto, auto);
}

.Post-cc {
    position: absolute;
    z-index: -1;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    background-color: #F3F1E7;
}

.Post {
    margin: 5px;
}

/* Start images */
a img {
    border: 0;
}

.article img, img.article {
    margin: 1em;
    width: 50%;
}

.metadata-icons img {
    border: none;
    vertical-align: middle;
    margin: 2px;
}

/* Finish images */

/* Start tables */

.article table, table.article {
    border-collapse: collapse;
    margin: 1px;
    width: auto;
}

.article table, table.article .article tr, .article th, .article td {
    background-color: transparent;
}

.article th, .article td {
    padding: 2px;
    border: solid 1px #B7AB71;
    vertical-align: top;
    text-align: left;
}

.article th {
    text-align: center;
    vertical-align: middle;
    padding: 7px;
}

/* Finish tables */
/* end Box, Post */

/* begin PostMetadata */
.PostMetadataHeader {
    padding: 1px;
    background-color: #E9E5D3;
    border: 1px solid #D4CDAA;
}

/* end PostMetadata */

/* begin PostHeaderIcon */
.PostHeaderIcon-wrapper {
    text-decoration: none;
    margin: 0.2em 0;
    padding: 0;
    font-weight: normal;
    font-style: normal;
    letter-spacing: normal;
    word-spacing: normal;
    font-variant: normal;
    text-transform: none;
    text-indent: 0;
    line-height: inherit;
    font-size: 31px;
    text-align: center;
    color: #4E4412;
}

.PostHeaderIcon-wrapper, .PostHeaderIcon-wrapper a, .PostHeaderIcon-wrapper a:link,
.PostHeaderIcon-wrapper a:visited, .PostHeaderIcon-wrapper a:hover {
    font-size: 31px;
    text-align: center;
    color: #4E4412;
}

/* end PostHeaderIcon */

/* begin PostHeader */
.PostHeader a:link {
    text-decoration: none;
    font-variant: small-caps;
    color: #635617;
}

.PostHeader a:visited, .PostHeader a.visited {
    text-decoration: none;
    font-variant: small-caps;
    color: #595D4B;
}

.PostHeader a:hover, .PostHeader a.hovered {
    text-decoration: none;
    font-variant: small-caps;
    color: #625A32;
}

/* end PostHeader */

/* begin PostContent */
/* Content Text Font & Color (Default) */
.PostContent {
    font-style: normal;
    font-weight: normal;
    text-align: justify;
    color: #4E5242;
}

/* Start Content link style */
/*
The right order of link pseudo-classes: Link-Visited-Hover-Focus-Active.
http://www.w3schools.com/CSS/css_pseudo_classes.asp
http://meyerweb.com/eric/thoughts/2007/06/11/who-ordered-the-link-states/
*/
a {
    text-decoration: underline;
    color: #84731F;
}

/* Adds special style to an unvisited link. */
a:link {
    text-decoration: underline;
    color: #84731F;
}

/* Adds special style to a visited link. */
a:visited, a.visited {
    color: #383B30;
}

/* :hover - adds special style to an element when you mouse over it. */
a:hover, a.hover {
    text-decoration: none;
    color: #6D6437;
}

/* Finish Content link style */

/* Reset some headings default style & links default style for links in headings*/
h1, h2, h3, h4, h5, h6,
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover,
h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
}

/* Start Content headings Fonts & Colors  */
h1, h1 a, h1 a:link, h1 a:visited, h1 a:hover {
    font-size: 33px;
    color: #565B49;
}

h2, h2 a, h2 a:link, h2 a:visited, h2 a:hover {
    font-size: 31px;
    color: #71775F;
}

h3, h3 a, h3 a:link, h3 a:visited, h3 a:hover {
    font-size: 20px;
    color: #8E947A;
}

h4, h4 a, h4 a:link, h4 a:visited, h4 a:hover {
    font-size: 18px;
    color: #646954;
}

h5, h5 a, h5 a:link, h5 a:visited, h5 a:hover {
    font-size: 15px;
    color: #646954;
}

h6, h6 a, h6 a:link, h6 a:visited, h6 a:hover {
    font-size: 13px;
    color: #646954;
}

/* Finish Content headings Fonts & Colors  */
/* end PostContent */

/* begin PostBullets */
/* Start Content list */
ol, ul {
    color: #3B3E32;
    margin: 1em 0 1em 2em;
    padding: 0;
    font-size: 13px;
}

li ol, li ul {
    margin: 0.5em 0 0.5em 2em;
    padding: 0;
}

li {
    margin: 0.2em 0;
    padding: 0;
}

ul {
    list-style-type: none;
}

ol {
    list-style-position: inside;

}

.Post li {
    padding: 0 0 0 14px;
    line-height: 1.2em;
}

.Post ol li, .Post ul ol li {
    background: none;
    padding-left: 0;
}

.Post ul li, .Post ol ul li {
    background: url('../images/PostBullets.png') no-repeat;
    padding-left: 14px;
}

/* Finish Content list */
/* end PostBullets */

/* begin PostQuote */
/* Start blockquote */
blockquote p {
    color: #10110E;
}

blockquote {/*
    margin: 10px 10px 10px 50px;*/
    padding: 5px 5px 5px 32px;
    background: #D8DBD2 url('../images/PostQuote.png') no-repeat left top;
}

/* Finish blockuote */
/* end PostQuote */

/* begin Button */
.Button {
    display: inline-block;
    width: auto;
    outline: none;
    border: none;
    background: none;
    line-height: 32px;
    margin: 0;
    padding: 0;
    overflow: visible;
    cursor: default;
    text-decoration: none !important;
    z-index: 0;
}

.Button .btn {
    display: block;
    position: relative;
    float: left;
    height: 32px;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
    color: #000000;
}

.Button .btn .t {
    display: block;
    height: 32px;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    white-space: nowrap;
    text-align: left;
    padding: 0 16px;
    line-height: 32px;
    text-decoration: none !important;
}

input, select {
    font-family: initial !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: bold !important;
}

table tr td {/*
    min-width: 30px;
    max-width: 420px;*/
}

.order_table select {
    padding: 4px;
    width: 100%;
}

.order_table input {
    height: 24px;
    padding: 0 4px;
    width: 80% !important;
}

.Button .hover, .Button:hover {
    color: #000000;
    text-decoration: none !important;
}

.Button .active {
    color: #FBF9EE;
}

.Button .btn .l, .Button .btn .r {
    display: block;
    position: absolute;
    z-index: -1;
    height: 96px;
    background-image: url('../images/Button.png');
}

.Button .btn .l {
    left: 0;
    right: 10px;
}

.Button .btn .r {
    width: 409px;
    right: 0;
    clip: rect(auto, auto, auto, 399px);
}

.Button .btn.hover .l, .Button .btn.hover .r, .Button .btn:hover .l, .Button .btn:hover .r {
    top: -32px;
}

.Button .btn.active .l, .Button .btn.active .r {
    top: -64px;
}

/* end Button */

/* begin Footer */
.Footer {
    position: relative;
    z-index: 0;
    overflow: hidden;
    max-width: 890px;
    margin: -24px auto 0 auto;
}

.Footer .Footer-inner {
    height: 1%;
    position: relative;
    z-index: 0;
    text-align: center;
    background: url('../images/Footer.png') no-repeat;
    background-size: 100% 80px;
    border-radius: 0 0 15px 15px;
}

.Footer-text a img {
    padding-top: 6px;
}

.footer-menu {
    text-align: center;
    font-size: 9pt;
}

.footer-menu a {
    padding: 0 3px;
}

.footer-search {
    padding-top: 12px;
    text-align: center;
}

.rss-tag-icon {
    position: relative;
    display: block;
    float: left;
    background: url('../images/rssIcon.png') no-repeat center right;
    margin: 0 5px 0 0;
    height: 30px;
    width: 30px;
}

.Footer .Footer-text p {
    margin: 0;
}

.Footer .Footer-text {
    display: inline-block;
    color: #1B190E;
    font-size: 12px;
    line-height: 15px;
    margin: 15px 0;
}

.Footer .Footer-text a:link {
    text-decoration: none;
    color: #625A32;
}

.Footer .Footer-text a:visited {
    color: #181914;
    text-decoration: none;
}

.Footer .Footer-text a:hover {
    color: #78691C;
    text-decoration: underline;
}

hr {
    border: solid #ddd;
    border-width: 1px 0 0;
    clear: both;
    margin: 10px 0 30px;
}

/* end Footer */

/* begin PageFooter */
.page-footer, .page-footer a, .page-footer a:link, .page-footer a:visited, .page-footer a:hover {
    font-size: 10px;
    letter-spacing: normal;
    word-spacing: normal;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    color: #A59027;
}

.page-footer {
    margin: 1em;
    padding-top: 4px;
    text-align: center;
    text-decoration: none;
    color: #887C44;
}

/* end PageFooter */

/* begin LayoutCell */
.contentLayout .content {
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    float: left;
    overflow: hidden;
    width: 100%;
}

.row {
    border: 3px solid black;
    padding: 10px;
}

.Post-inner .row {
    margin: 6px auto;
    border-radius: 6px;
}

.choc-img {
    text-align: center;
    font-weight: bold;
    padding-bottom: 16px;
}

.choc-img img {
    width: 180px;
    margin: 0 auto;
}

.prod_price_desc {
    font-size: 9pt;
    border: 1px solid black;
    padding: 12px;
    width: 40%;
    margin: 0 auto 6px;
    display: table;
}

.nut-img {
    text-align: center;
    font-weight: bold;
    padding-bottom: 16px;
}

.nut-img img {
    width: 180px;
    margin: 0 auto;
}

.store-img {
    text-align: center;
}

h5 {
    font-weight: bold;
    text-align: center;
}

.addresshours {
    font-size: 15px;
    text-align: center;
    margin-top: 18px;
}

table .location {
    float: left;
    width: 277px;
    font-size: 14px;
    text-align: justify;
    padding: 20px;
}

.maps {
    text-align: center;
    padding-right: 65px;
}

/* end LayoutCell */

/* begin price */
.price_desc {
    margin-right: 16px;
    font-size: 9pt;
    border: 1px solid black;
    padding: 6px;
    width: 40%;
    float: right;
}

/* end price */

/* begin price_left */
.price_left {
    float: left;
    clear: left;
}

/* end price_left */

/* begin price_right */
.price_right {
    float: right;
    clear: right;
}

/* end price_right */

/* begin prod_desc */
.prod_desc {
    font-size: 9.5pt;
    border: 1px solid black;
    padding: 6px;
    width: 450px;
    float: left;
    height: fit-content;
}

/* end prod_desc */

input[type="submit"] {
    padding: 3px 10px;
}

/* Order Page */
.order_table {
    margin: 0 auto;
    text-align: center;
    border: none #111111;
    width: 100%;
}

.order_table thead th {
    background-color: #000000;
    font-weight: bold;
    text-align: center;
    color: #FFFFFF;
    font-family: Tahoma, serif;
    font-size: 10pt;
    padding: 6px 0;
}

.price_list td {
    display: table-cell;
    border-collapse: separate;
    border-spacing: 2px;
    border-color: gray;
}

.subheading tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

.ship2row {
    text-align: left;
    font-family: Tahoma, serif;
    font-size: 10pt;
    margin: 0 auto;
    width: 95%;
}

.del_row {
    font-family: Tahoma, serif !important;
    font-size: 12pt;
    color: red !important;
    font-weight: bold;
}

.ship2sectionheader {
    background-color: #C0C0C0;
    color: #000000;
    font-weight: bold;
    text-align: center;
    font-family: Tahoma, serif;
    font-size: 10pt;
    padding: 6px 0;
}

.ship2desc {
    text-align: right;
    padding-left: 130px;
    font-family: Tahoma, serif;
    font-size: 10pt;
}

.ship2item {
    text-align: left;
    font-family: Tahoma, serif;
    font-size: 10pt;
}

.del_recip {
    text-align: center;
    width: 100%;
}

.del_recip a {
    color: red;
    font-weight: bold;
    font-family: Tahoma, serif;
    font-size: 10pt;
}

#main_table {
    text-align: center;
    width: 100%;
    border-color: #111111;
}

#main_heading {
    font-weight: bold;
    text-align: center;
    font-family: Tahoma, serif;
    font-size: 14pt;
}

#instructions {
    text-align: center;
    font-family: Tahoma, serif;
    font-size: 10pt;
    font-style: italic;
}

#address {
    font-size: small;
    color: black;
    font-family: Tahoma, serif;
}

.inner_table {
    margin: 0 auto;
    font-family: Tahoma, serif;
    font-size: 10pt;
    text-align: center;
    width: 90%;
    border: none #111111;
    border-collapse: separate;
    border-spacing: 3px;
}

.subheading {
    background-color: #C0C0C0;
    font-weight: bold;
    color: black;
    width: 95%;
    padding: 6px 0;
    margin: 0;
}

.level3table {
    width: 80%;
    margin: 0 auto;
}

.leftcol {
    text-align: right;
    width: 25%;
}

.rightcol {
    text-align: left;
}

#cc_warning {
    font-weight: bold;
    color: red;
    text-align: left;
    padding: 0 20px;
}

.instructions {
    text-align: left;
    padding: 0 20px;
}

table .cc {
    width: 50%;
    margin: 0 auto;
}

.notes_table {
    margin: 0 auto;
    text-align: center;
    width: 80%;
    border: none #111111;
    height: 150px;
}

#notes {
    height: 100px;
    width: 90%;
}

.nut_heading {
    margin-top: 16px;
    text-align: center;
    font-weight: bold;
}

.discount_sh_heading {
    width: 100%;
    padding: 6px 0;
    background-color: #000000;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
    margin: 0;
}

.history-contact-instructions {
    font-style: italic;
    padding: 24px 0 0;
}

.alert {
    padding: 8px 35px 8px 14px;
    margin-bottom: 20px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    background-color: #fcf8e3;
    border: 1px solid #fbeed5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.alert, .alert h4 {
    color: #c09853;
}

.alert h4 {
    margin: 0;
}

.alert .close {
    position: relative;
    top: -2px;
    right: -21px;
    line-height: 20px;
}

.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #468847;
}

.alert-success h4 {
    color: #468847;
}

.alert-danger, .alert-error {
    background-color: #f2dede;
    border-color: #eed3d7;
    color: #b94a48;
}

.alert-danger h4, .alert-error h4 {
    color: #b94a48;
}

.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #3a87ad;
}

.alert-info h4 {
    color: #3a87ad;
}

.alert-block {
    padding-top: 14px;
    padding-bottom: 14px;
}

.alert-block > p, .alert-block > ul {
    margin-bottom: 0;
}

.alert-block p + p {
    margin-top: 5px;
}

/* custom styles */
nav.nav select {
    display: none;
}

.product-desc {
    width: 80%;
}

.modal-backdrop.show {
    z-index: -1;
}

/* media-queries */
@media all and (max-width: 958px) {
    div.Header-png {
        height: 170px;
        background: url('../images/Header_med.png') no-repeat left top;
        background-size: 100% 100%;
    }

    .Post-body {
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;
        width: 95%;
    }

    .Footer .Footer-inner {
        height: 1%;
        position: relative;
        z-index: 0;
        text-align: center;
        margin-left: 4px;
        background: url('../images/Footer.png') no-repeat;
        background-size: 99% 80px;
        border-radius: 0 0 15px 15px;
    }

    .prod_price_desc {
        width: 90%;
    }
}

@media (max-width: 767px) {
    div.Header-png {
        height: 92px;
        background: url('../images/Header_small.png') no-repeat left top;
        background-size: 100% 100%;
    }

    footer input[type="text"] {
        width: 95px;
    }

    .Footer {
        margin: -20px auto 0;
    }

    .Footer .Footer-inner {
        background: url('../images/Footer.png') no-repeat;
        background-size: 99% 86px;
        border-radius: 0 0 15px 15px;
    }

    .Footer .Footer-text {
        line-height: 10px;
    }

    .price_desc, .prod_desc {
        font-size: 9pt;
        border: 1px solid black;
        padding: 12px;
        width: 90%;
        margin: 0 auto 6px;
        display: table;
        clear: both;
        float: none;
    }

    .location {
        float: none;
        margin: 0 auto;
    }

    .maps {
        float: none;
        padding-right: 0;
        margin: 0 auto;
        width: 215px;
        padding-top: 16px;
    }

    blockquote {
        margin: 10px 0 10px 0;
        width: 85%;
    }

    nav.nav ul {
        display: none;
    }

    nav.nav select {
        display: block;
        width: 100%;
    }

    #emaillink, #order, #orderlink {
        display: none;
    }
}

@media (max-width: 40em) {
    table, thead, tbody, tfoot, th, td, tr {
        display: block;
    }

    tr + tr {
        margin-top: 1em;
    }

    .history-contact-table, .history-contact-table thead, .history-contact-table tbody, .history-contact-table tfoot,
    .history-contact-table th, .history-contact-table td {
        display: table-cell;
        vertical-align: text-top;
    }

    .history-contact-table, .history-contact-table tr {
        display: table-row;
        width: 100%;
    }

    .history-contact-instructions {
        padding: 24px 0 0;
        text-align: justify;
        font-style: italic;
    }
}

/* end media-queries */
